import Vue from 'vue'
import Router from 'vue-router'
// import PhotographyHome from './components/Photography/PhotographyHome.vue'
// import HomePage from './components/HomePage/HomePage'
const HomePage=()=>import('./components/HomePage/HomePage')
const PhotographyHome=()=>import('./components/Photography/PhotographyHome.vue')
const GlogsIndex=()=>import('./components/GlogsIndex/GlogsIndex.vue')
const haohaoAI=()=>import('./components/haohaoAI/haohaoAI.vue')
const chatUI=()=>import('./components/haohaoAI/chatUI.vue')
// import PhotographySanya from './components/Photography/PhotographySanya'
Vue.use(Router)
const router = new Router({
    routes:[
        { path: '/', redirect: '/PageHome' },
        {path:'/photographyhome',component:PhotographyHome},
        // {path:'/PhotographySanya',component:PhotographySanya},
        {path:'/PageHome',component:HomePage},
        {path:'/GlogsIndex',component:GlogsIndex},
        {path:'/haohaoAI',component:haohaoAI},
        {path:'/chatUI',component:chatUI},
    ],
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0}
    }
    
})
export default router
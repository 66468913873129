import Vue from 'vue'
import App from './App.vue'
import router from './router'


//导入全局样式表
import './assets/css/global.css'
// //导入轮播图组件
// import EasySlider from 'vue-easy-slider'
// Vue.use(EasySlider)
// 导入图标
import '@/assets/icon/iconfont.css'
//导入axios
import axios from 'axios'
Vue.prototype.$axios = axios
// 引入element-ui
import {Button,Input,Message,progress} from 'element-ui'
Vue.component('el-button',Button)
Vue.component('el-input',Input)
Vue.component('el-progress',progress)
Vue.prototype.$message = Message
// 关闭生产模式提醒
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')



<template>
  <div id="app">
<router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
#app{
  background-color: #1f2126;
}
@font-face {
  font-family: 'iconfont';  /* Project id 3828699 */
  src: url('//at.alicdn.com/t/c/font_3828699_hhoqx7zsk8d.woff2?t=1682776293784') format('woff2'),
       url('//at.alicdn.com/t/c/font_3828699_hhoqx7zsk8d.woff?t=1682776293784') format('woff'),
       url('//at.alicdn.com/t/c/font_3828699_hhoqx7zsk8d.ttf?t=1682776293784') format('truetype');
}  .iconfont {
    font-family:"iconfont" !important;
    font-size:16px;
    font-style:normal;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    -moz-osx-font-smoothing: grayscale;
}
</style>
